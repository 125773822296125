// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-js": () => import("/Users/zm/Code/Portfolio_Projects/La_Bohemia/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/Users/zm/Code/Portfolio_Projects/La_Bohemia/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("/Users/zm/Code/Portfolio_Projects/La_Bohemia/src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-menu-js": () => import("/Users/zm/Code/Portfolio_Projects/La_Bohemia/src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-order-js": () => import("/Users/zm/Code/Portfolio_Projects/La_Bohemia/src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/zm/Code/Portfolio_Projects/La_Bohemia/.cache/data.json")

